








































































import { defineComponent, PropType, ref } from '@vue/composition-api'

import { Loan, Type } from '@/views/SingleLoan.vue'
import DownloadList from '@/components/DownloadList.vue'
import PaymentProof from '@/components/popups/PaymentProof.vue'
import DeathCertificate from '@/components/popups/DeathCertificate.vue'
import Reimbursement from '@/components/popups/Reimbursement.vue'
import { useGetters } from '@u3u/vue-hooks'
import { emailSessionUsageKey } from '../popups/EmailUsage.vue'

export default defineComponent({
  name: 'recap',
  components: { DownloadList, PaymentProof, DeathCertificate, Reimbursement },
  props: {
    loan: {
      type: Object as PropType<Loan>,
      required: true,
    },
  },

  setup(props, ctx) {
    const { user } = useGetters('user', ['user'])

    const type = ref<Type>(ctx.root.$route.meta.type)
    const paymentProofVisible = ref(false)
    const deathCertificateVisible = ref(false)
    const rbaVisible = ref(false)
    const rbaId = ref('')
    const docRequestConfirmationVisible = ref(false)
    const isEmailUsageVisible = ref(!user.value.gdprOptin && !sessionStorage.getItem(emailSessionUsageKey))

    const onDocRequest = (id: string) => {
      ctx.emit('requested', id)
    }

    if (isEmailUsageVisible.value) {
      ctx.emit('show-consent-popup')
    }

    return {
      rbaId,
      onDocRequest,
      paymentProofVisible,
      deathCertificateVisible,
      rbaVisible,
      docRequestConfirmationVisible,
      type,
    }
  },
})
