




































import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'management',
  components: {},
  props: {},

  setup() {
    return {}
  },
})
